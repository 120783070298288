@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&family=Overpass:wght@300&display=swap');
@import url('https://fonts.cdnfonts.com/css/miratrix');

@import url('./styles/_variables.css');
@import url('./styles/_icons.css');
@import url('./styles/buttons.css');
@import url('./styles/typography.css');

@import url('./styles/cards_group.css');
@import url('./styles/button_group.css');
@import url('./styles/navbar.css');
@import url('./styles/cover.css');
@import url('./styles/invite.css');
@import url('./styles/Cards.css');
@import url('./styles/news_card.css');
@import url('./styles/newsCard-group.css');
@import url('./styles/footer.css');




*,
*::before,
*::after {
  box-sizing: border-box;
  user-select: none;
}

*::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
  }

html, body {        
    margin: 0;
    padding: 0;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
}

body::-webkit-scrollbar {
    display: none;
  }

img {
    max-width: 100%;
}

html {
    background-color: #0D0C0C;
    overflow-y: scroll;
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8vw;
    background-repeat: no-repeat;
    width: 100vw;
    overflow-x: hidden;
}

@media (max-width: 412px) {
    .app {
        background-image: none;
        gap: 120px;
    }
} 
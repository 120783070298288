.header-1 {
    font-family: 'Miratrix';
    font-size: 64px;
    line-height: 100%;
}

.header-2 {
    font-family: 'Miratrix';
    font-size: 48px;
    line-height: 100%;
}

.header-3 {
    font-family: 'Manrope';
    font-size: 22px;
    font-weight: 500;
    line-height: 110%;
}

.description-1 {
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;
}

.description-2 {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
}

.button-text, 
a {
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 500;
    line-height: 100%;
}

@media (max-width: 1440px) {
    .header-1 {
        font-size: 46px;
    }

    .header-2 {
        font-size: 32px;
    }

    .header-3 {
        font-size: 20px;
    }

    .description-2 {
        font-size: 14px;
    }
    
    .button-text {
        font-size: 16px;
    }
}

@media (max-width: 1280px) {
    .header-1 {
        
    }

    .header-2 {
        font-size: 32px;
    }

    .header-3 {
        font-size: 22px;
    }

    .description-1 {
        font-size: 16px;
    }

    .description-2 {
        font-size: 16px;
    }

    .button-text,
    a {
        font-size: 14px;
    }
}

@media (max-width: 800px) {
    .description-1 {
        font-size: 20px;
    }
}

@media (max-width: 576px) {
    .header-1 {
        font-size: 32px;
    }

    .header-2 {
        font-size: 32px;
    }

    .header-3 {
        font-size: 20px;
    }

    .description-1 {
        font-size: 16px;
    }

    .description-2 {
        font-size: 14px;
    }

    .button-text,
    a {
        font-size: 12px;
    }
}

@media (max-width: 360px) {
    .button-text,
    a {
        font-size: 12px;
    }
}
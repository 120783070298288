.icon {
    height: 16px;
    margin-left: 2px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.arrow-up-black {
    width: 16px;
    background-image: url('../img/icon-arrow-up-black.svg');
}

.arrow-up-white {
    width: 16px;
    background-image: url('../img/icon-arrow-up-white.svg');
}

.mail-black {
    width: 16px;
    background-image: url('../img/icon-mail-black.svg');
}

@media (max-width: 800px) {
    .icon.menu-burger {
        width: 34px;
        height: 34px;
        background-image: url('../img/menu-burger.svg');
    }
} 

@media (max-width:576px) {
    .icon {
        height: 12px;
    }

    .arrow-up-black,
    .arrow-up-white,
    .mail-black {
        width: 12px;
    }

    .icon.menu-burger {
        width: 28px;
        height: 28px;
        background-image: url('../img/menu-burger.svg');
    }
}
.invite {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    /*margin-bottom: 120px;*/
}

.invite .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    gap: 40px;
    width: 1160px;
    height: fit-content;
    background-color: var(--gray-4);
    border-radius: 16px;
    padding: 40px 56px;
}

.invite__title {
    color: var(--red);
}

.invite .container p {
    width: 600px;
    color: #FFF;
}

video {
    position: absolute;
    top: 0;
    z-index: -1;

    width: 100vw;
    height: calc(200vh + 8vw);

    object-fit: cover;
    /*object-position: center top;*/
}

@media (max-width: 1440px) {
    .invite  .container {
        width: 62.5vw;
        padding: 1.88vw;
        gap: 16px;
    }

    .invite .container p {
        width: 31.25vw;
    }

    .video {
        height: calc(200vh + 120px);
    }

}

@media (max-width: 1280px) {
    .invite .container {
        width: 78vw;
        padding: 3.1vw 2.3vw;
    }

    .invite .container .header-1 {
        font-size: max(40px, 3.9vw);
    }

    .invite .container p {
        width: 36vw;
    }
}

@media (max-width: 800px) {
    .invite .container {
        width: 94vw;
        padding: 5vw 3vw;
        flex-wrap: nowrap;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 16px;
    }

    .invite .container p {
        width: 60vw;
    }

    .video {
        height: calc(200vh + 120px);
    }

}

@media (max-width: 576px) {
    .invite .container {
        width: 94vw;
        padding: 28px;
        flex-wrap: nowrap;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 16px;
    }

    .invite .container p {
        width: 100%;
    }

    .video {
        height: calc(200vh + 120px);
    }

}
.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Manrope';
}

.navbar__menu {
    text-align: center;
    display: flex;
    gap: 120px;
    align-items: center;

}
.navbar__menu a {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
} 

.button-text.neutral a {
    color: #fff;
}

.navbar img {
    width: 56px;
    height: 56px;
    margin-right: 140px;
}

.menu-btn {
    display: none;
}

@media (max-width: 1440px) {
    .navbar__menu {
        gap: 6.25vw;
    }
    

    .navbar__menu a {
        font-size: 16px;
    } 
    
    .navbar img {
        width: 48px;
        height: 48px;
        margin: 0;
    }
}

@media (max-width: 800px) {
    /* .nav-container {
        position: fixed;
        width: 94vw;
        z-index: 10000;
        background-color: var(--gray-4);
        padding: 3vw;
        border-radius: 12px;
        margin-top: 3vw;
    } */


    .navbar {
        position: fixed;
        width: 94vw;
        z-index: 10000;
        background-color: var(--gray-4);
        padding: 3vw;
        border-radius: 12px;
        margin-top: 3vw;
    } 

    .navbar img {
        align-self: flex-start;
        width: 44px;
        height: 44px;
        margin: 0;
    }

    .nav__join {
        display: none;
    }

    .navbar__menu {
        display: none;
        flex-direction: column;
    }

    .navbar.active {
        height: calc(100vh - 6vw);
    }

    .navbar.active .navbar__menu {
        display: flex;
    }

    .menu-btn {
        display: block;
        align-self: flex-start;
    }



    

    /*.navbar__menu {
        order: 2;
        display: flex;
        flex-direction: column;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateX(120%);
        transition: transform 0.5s;
    }


    .navbar__menu.active {
        transform: translateX(0);
    }

    .navbar__menu li {
        list-style-type: none;
    } */

    .menu-btn {
        order: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        flex-direction: column;
        width: 30px;
        height: 30px;
    }

    .menu-btn span {
        width: 30px;
        height: 2px;
        background-color: #fff;
        /* transform: translate(-50%, -50%); */
        transition: all 0.3s;
    }

    .navbar.active .menu-btn span:nth-of-type(1) {
        top: 50%;
        transform: rotate(-45deg) translate(0, -50%);
        transition: all .3s;
    }

    .navbar.active .menu-btn span:nth-of-type(2) {
        display: none;
    }

    .navbar.active .menu-btn span:nth-of-type(3) {
        bottom: 50%;
        transform: rotate(45deg) translate(0, 50%);
        transition: all .3s;
    }
    /*.menu-btn span:nth-of-type(2) {
        top: calc(50% - 5px);
    }

    .menu-btn span:nth-of-type(3) {
        top: calc(50% + 5px);
    } */
}

@media (max-width: 576px) {
    .navbar {
        padding: 16px;
    }

    .navbar img {
        width: 40px;
        height: 40px;
        margin: 0;
    }

}
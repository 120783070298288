.news {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 328px;
    background-color: var(--gray-4);
    padding-bottom: 16px;
    border-radius: 12px;
}

.news__img {
    width: 100%;
    border-radius: 12px 12px 0 0;
}

.news p {
    color: #fff;
}

.news__text-container {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    gap: 8px;
}

@media (max-width: 1440px) {
    .news {
        width: 21.2vw;
        height: fit-content;
    }

    .news__text-container {
        padding: 0 12px;
        gap: 8px;
    }
}

@media (max-width: 1280px) {
    .news {
        min-width: 28vw;
    }
}

@media (max-width: 800px) {
    .news {
        min-width: 50vw;
    }
}

@media (max-width: 576px) {
    .news {
        min-width: 80vw;
    }
}
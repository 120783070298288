.header {
    color: var(--white);
    margin-bottom: 48px;
}
.newsCardGroup {
    width: 1408px;
    height: 1100px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 32px;
}

@media (max-width: 1440px) {
    .header {
        margin-bottom: 24px;
    }

    .newsCardGroup {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 95vw;
        gap: 3.3vw;
        padding: 0;
        height: auto;
        max-height: 100vh;
    }

}

@media (max-width: 1280px) {
    .header {
        margin-left: 2.5vw;
    }

    .newsCardGroup {
        width: 100vw;
        height: auto;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        gap: 1.5vw;
        padding: 0 2.5vw;
    }

    .newsCardGroup::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 576px) {
    .header {
        margin-bottom: 24px;
        margin-left: 3vw;
    }

    .newsCardGroup {
        width: 100vw;
        height: auto;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        gap: 14px;
        padding: 0 3vw;
    }

    .newsCardGroup::-webkit-scrollbar {
        display: none;
    }
}
.footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--gray-4);
    color: #FFF;
}

.footer .container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 1408px;
    padding: 48px 0;
}

.footer__support {
    width: 33%;
}

.footer__title {
    padding-bottom: 16px;
}

.footer__description {
    padding-bottom: 40px;
}

.footer__copyright {
    display: flex;
    align-items: flex-end;
    font-family: 'Manrope';
    justify-content: space-around;
    width: 33%;
}

.footer__logo {
    display: flex;
    padding-bottom: 100px;
}

.footer__contact {
    padding-left: 200px;
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 16px;
}

.footer__contact-icon {
    width: 58px;
    height: 58px;
}

@media (max-width:1440px) {
    .footer .container {
        width: 95vw;
        padding: 32px 0;
    }
    
    .footer__support,
    .footer__copyright,
    .footer__contact {
        width: 31.66vw;
    }

    .footer__logo {
        width: 15vw;
        padding: 0;
    }

    .footer__contact {
        padding-left: 16.66vw;
        justify-content: space-between;
        align-content: space-between;
    }

    .footer__contact-icon {
        width: 3.5vw;
        height: 3.5vw;
    }
}

@media (max-width: 800px) {
    .footer__description {
        font-size: 18px;
    }

    .footer__contact {
        gap: 12.96px;
    }
}

@media (max-width:576px) {
    .footer {
        width: 100vw;
        flex-direction: column;
        align-items: center;
        gap: 6vw;
        padding: 0;
    }

    .footer .container {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }

    .footer__support {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 80%;
        min-width: 300px;
        order: 1;
    }

    .footer__title {
        text-align: center;
    }

    .footer__description {
        text-align: center;
        padding-bottom: 20px;
    }

    .footer__copyright {
        width: 100%;
        order: 3;
    }

    .footer__contact {
        display: flex;
        width: 100%;
        order: 2;
        justify-content: center;
        align-content: center;
        flex-direction: row;
        padding: 0;
    }

    .footer__logo {
        display: none;
    }

    .footer__contact-icon {
        min-width: 40px;
        min-height: 40px;
    }
}
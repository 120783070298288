.card {  
    display: flex;
    flex-direction: row;
    gap: 32px;
    background-color: var(--gray-4);
    border-radius: 12px;
    align-items: stretch;
}

.card.big {
    width: 1408px;
}

.card.small {
    width: 688px;
}

.card .text-container {
    color: #FFF;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
}

.card.big .text-container {
    padding: 40px;
}

.card.small .text-container {
    padding: 40px 0 40px 40px;
}

.card.big .header-1{
    padding-bottom: 24px;
}

.card.small .header-2{
    padding-bottom: 18px;
}

.bigcard__img  {
    width: 50%;
}

.bigcard__img img {
    border-radius: 0 12px 12px 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

@media (max-width: 1440px) {
    .card {  
        gap: 2.5vw;
    }

    .card.big {
        width: 95vw;
        /*max-height: 90vh;*/
    }
    
    .card.small {
        width: 46.55vw;
    }

    .card.big .text-container {
        width: 46.25vw;
        padding: 1.88vw 2.5vw 1.88vw 1.88vw;
    }
    .card.small .text-container {
        width: 21.875vw;
        padding: 1.88vw;
    };

    .big .bigcard__img  {
        width: 46.25vw;
    }

    .small .bigcard__img  {
        width: 21.875vw;
    }
}

@media (max-width: 1280px) {
    .card.big .text-container,
    .card.small .text-container {
        padding: 2.3vw;
    }
}

@media (max-width: 800px) {
    .card.big,
    .card.small {
        width: 94vw;
        height: fit-content;
        flex-direction: column;
        gap: 0;
    }
    

    .card.big .text-container,
    .card.small .text-container {
        width: 100%;
        order: 2;
        padding: 16px;
        
    }

    .card.big .header-1,
    .card.big .header-3,
    .card.small .header-2,
    .description-1 {
        padding-bottom: 16px;
    }

    .bigcard__img{
        width: 94vw;
        height: 70.5vw;
        order: 1;
    }

    .bigcard__img img {
        border-radius: 12px 12px 0 0;
        object-fit: cover;
        object-position: top center;
    }

    .card-button-group {
        display: flex;
        justify-content: center;
    }

    .small .bigcard__img  {
        width: 93vw;
    }
}

@media (max-width: 576px) {
    .card.big,
    .card.small {
        width: 94vw;
        flex-direction: column;
        gap: 0;
    }
    

    .card.big .text-container,
    .card.small .text-container {
        width: 100%;
        order: 2;
        padding: 16px;
        
    }

    .card.big .header-1,
    .card.big .header-3,
    .card.small .header-2,
    .description-1 {
        padding-bottom: 16px;
    }

    .bigcard__img{
        width: 94vw;
        height: 70.5vw;
        order: 1;
    }

    .bigcard__img img {
        border-radius: 12px 12px 0 0;
        object-fit: cover;
        object-position: top center;
    }

    .card-button-group {
        display: flex;
        justify-content: center;
    }
}
:root {
    /*
    COLORS
    */
    --main-bg-color: #0D0C0C;
    --white: #FFFFFF;
    --black: #000000;
    --gray-1: #535050;
    --gray-2: #343232;
    --gray-3: #222020;
    --gray-4: #151414;
    --red: #FF0D21;
    --blue: #2315F2;
    --green: #00C7A1;
}
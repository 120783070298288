.card-group {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;

    width: 1408px;
}

@media (max-width:1440px) {
    .card-group {
        width: 95vw;
        gap: 1.88vw;
    }
}


@media (max-width:576px) {
    .card-group {
        width: 94vw;
        flex-wrap: nowrap;
        flex-direction: column;
    }

}
.cover {
    width: 96vw;
    height: 94vh;
    margin: 3vh 0;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    background-color: var(--gray-4);
    /*background-image: url('../../img/background.png');*/
    /*background-size: cover;*/
    flex-direction: column;
    padding: 24px 40px 40px;
}

.cover__hagao {
    width: 448px;
    height: 150px;
}

@media (max-width: 1440px) {
    .cover {
        width: 95vw;
        height: calc(100vh - 5vw);
        margin: 2.5vw 0;
        padding: 1.88vw;
    }
    .cover__hagao {
        width: 36.25vw;
        height: 11.72vw;
        object-fit: contain;
        object-position: bottom left;
    }
}

@media (max-width: 1280px) {
    .cover {
        padding: 2.3vw 2.3vw 3.1vw;
    }

    .cover__hagao {
        width: 39.1vw;
        height: 13vw;
    }
}

@media (max-width: 800px) {
    .cover {
        width: 94vw;
        height: calc(100vh - 6vw);
        margin: 3vw 0;
        margin-top: 5vw;
        padding: 40vh 3vw 4vw;
    }

    .cover__hagao {
        width: 58vw;
        height: 19.5vw;
    }
}

@media (max-width: 576px) {
    .cover {
        padding: 40vh 16px 24px;
    }

    .cover__hagao {
        width: 100%;
        object-fit: contain;
        object-position: bottom left;
    }
}
.btn__group {
    display: flex;
    gap: 24px;
}

@media (max-width:1440px) {
    .btn__group {
        padding: 1.25vw;
    }
}

@media (max-width:576px) {
    .btn__group {
        gap: 12px;
    }
}
.button {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px 28px;
    border-radius: 8px;
    width: fit-content;
}

.neutral a {
    color: var(--white);
}

.accent a {
    color: var(--black);
    
}

.neutral {
    background-color: var(--gray-3);
    color: var(--white);
}

.accent {
    background-color: var(--white);
    color: var(--black);
}

@media (max-width: 1440px) {
    .button {
        padding: 18px 26px;
    }
}

@media (max-width: 1280px) {
    .button {
        padding: 16px 24px;
    }
}